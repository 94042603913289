export default {
  login: {
    formTitle: 'Bei TripUp anmelden',
    signIn: 'Willkommen bei der TripUp App',
    email: 'Benutzername oder E-Mail-Adresse',
    forgotPassword: 'Passwort vergessen?',
    password: 'Passwort',
    signInCaption: 'Anmelden',
    capsLockOn: 'Die Feststelltaste ist aktiviert'
  },
  resetPassword: {
    title: 'Passwort zurücksetzen',
    subTitle: 'Geben Sie Ihre E-Mail-Adresse ein',
    submit: 'Passwort zurücksetzen',
    emailPlaceholder: 'E-Mail-Adresse',
    backToLogin: 'Zurück zur Anmeldeseite'
  },
  setPassword: {
    title: 'Neues Passwort festlegen',
    subTitle: 'Bitte füllen Sie die Felder aus, um Ihr Passwort zu ändern',
    submit: 'Passwort ändern',
    emailPlaceholder: 'E-Mail-Adresse',
    passwordPlaceholder: 'Aktuelles Passwort',
    newPasswordPlaceholder: 'Neues Passwort',
    confirmPasswordPlaceholder: 'Passwort bestätigen',
    logout: 'Abmelden'
  },
  profile: {
    back: 'Zurück',
    logout: 'Abmelden',
    user_profile: 'Profil',
    email: 'E-Mail',
    nickname: 'Benutzername',
    firstname: 'Vorname',
    lastname: 'Nachname',
    full_name: 'Vollständiger Name',
    user_info: 'Benutzerdaten',
    change_profile_password: 'Password ändern'
  },
  formRules: {
    required: 'Dieses Feld ist erforderlich',
    email: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
    password: 'Bitte geben Sie ein gültiges Passwort ein',
    passwordConfirm: 'Bitte geben Sie eine gültige Passwortbestätigung ein',
    passwordMatch: 'Die Passwörter stimmen nicht überein',
    passMinLen: 'Das Passwort darf nicht weniger als {len} Zeichen lang sein',
    minLength: 'Die Länge sollte mehr als {len} sein',
    confirmPasswordInvalid: 'Die beiden Eingaben stimmen nicht überein!',
    confirmPassword: 'Bitte geben Sie das neue Passwort erneut ein',
    currentPassword: 'Bitte geben Sie Ihr aktuelles Passwort ein',
    newPassword: 'Bitte geben Sie ein neues Passwort ein'
  }
}
